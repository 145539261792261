const marker = document.querySelector('#line');
const item = document.querySelectorAll('nav a');

console.log(item)

function movement(e) {
    marker.style.right = e.offsetRight + 'px';
    marker.style.width = e.offsetWidth + 'px';

}

item.forEach(link => {
    let border = true;
    link.addEventListener('click', (e) => {
        movement(e.target);
        if (border) {
            link.style.border = 'none'
        } else {
            link.style.border = '1px solid #aaa'
        }
    })
});
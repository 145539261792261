import React from 'react';
import './constructor.css'

class Construct extends React.Component {
    render() {

        return (
            <div id='constructor'>
                <p>
                    با عرض پوزش سایت در حال به روز رسانی می‌باشد
                </p>
            </div >
        )
    }
}

export { Construct };

import React, { Component } from 'react';
// import logo from './logo.svg';
// import logo2 from '../Assets/Images/zutique.png';
import './App.css';
import { Home, NavBar, Construct } from './Components';

const App = () => {
  return (
    <div className="App">
      {/* <NavBar /> */}
      <Construct />
      <Home />
    </div>
  )
}

export default App;

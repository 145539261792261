import React from 'react';
import './Home.css';

class Home extends React.Component {
    render() {
        return (
            <div className="site">
                <div className="overlay-background"></div>
            </div>
        )
    }
}

export { Home };

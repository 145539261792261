import React from 'react';
import './Nav.css';
import './Nav'


class NavBar extends React.Component {
    render() {

        return (
            <div id='indicator'>
                <nav className='indicator'>
                    <div id="line"></div>
                    <a href="#">خانه</a>
                    <a href="#">فروشگاه</a>
                </nav>
            </div >
        )
    }
}

export { NavBar };
